import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Image, Typography} from '@fupa/fupa-uikit';
import {object} from 'prop-types';
import ActivityHeader from 'app/components/stream/activities/activityHeader';
import {Football, Card, TwoCards, PenaltyMissed} from 'app/components/icons';
import {Alarm, Person} from '@mui/icons-material';
import {FlexContainer} from 'app/styles/content';
import {LinkOnComponent} from 'app/components/links/link';
import {
  Content,
  Container,
  EventInformation,
  Minute,
  DataContainer,
  ImageContainer,
  GoalInformation,
  Text,
} from 'app/components/matchEvents/MatchEventStyles';
import {ProfileTypography} from 'app/components/profile/ProfileStyles';
import {goalType} from 'app/helpers/goalType.const';

const Assist = styled.div`
  padding-top: ${SIZES['8']};
`;

const MatchEventActivity = ({activity}) => {
  const {
    homeTeam,
    homeGoal,
    awayTeam,
    awayGoal,
    minute,
    additionalMinute,
    type,
    subtype,
    eventTeamName,
    text,
    primaryRole,
    secondaryRole,
  } = activity.activity;
  const isDeactivated = primaryRole?.isDeactivated;
  const subtitle = (homeTeam?.name ?? 'N/A') + ' - ' + (awayTeam?.name ?? 'N/A');
  const time = additionalMinute > 0 ? `${minute}'+${additionalMinute}` : `${minute}'`;

  const playerName = primaryRole ? primaryRole.firstName + ' ' + primaryRole.lastName : 'Unbekannt';
  const playerImage = primaryRole?.image ? (
    <Image entity='player' src={primaryRole.image} ratio='1:1' width={64} height={64} circle={true} />
  ) : (
    <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
  );

  let icon, standingMessage, displayGoalType, assist;

  // Variant #1: type=goal
  if (type === 'goal') {
    icon = <Football style={{color: subtype?.startsWith('penalty_') ? COLORS.green : COLORS.darkGrey, fontSize: 16}} />; // Temporary-Fix for missing subtype
    const suffix = eventTeamName ? `für ${eventTeamName}` : null;
    standingMessage = `${homeGoal}:${awayGoal} Tor ${suffix}`;
    let showCount = '';
    if (subtype !== 'goal_own_goal' && subtype !== 'penalty_shootout') {
      showCount = primaryRole?.seasonCount ? `${primaryRole.seasonCount}. Saisontor` : '';
      // Temporary-Fix for missing subtype
      if (subtype && subtype !== 'goal_shoot') {
        showCount += ' -';
      }
    }
    displayGoalType = subtype ? `${showCount} ${goalType[subtype]}` : showCount; // Temporary-Fix for missing subtype
    if (secondaryRole) {
      const count = secondaryRole.seasonCount ? `(${secondaryRole.seasonCount}. Saisonvorlage)` : '';
      assist = `${secondaryRole.firstName} ${secondaryRole.lastName} ${count}`;
    }
  }

  // Variant #2: type=card
  if (type === 'card') {
    let count = '';
    if (subtype === 'card_red') {
      icon = <Card style={{color: COLORS.red, fontSize: 16}} />;
      standingMessage = `Rot für ${eventTeamName}`;
      count = primaryRole?.seasonCount ? `(${primaryRole.seasonCount}. Rote Karte)` : '';
    } else if (subtype === 'card_yellow') {
      // Currently, yellow cards are returned from stream service; remove this case later
      icon = <Card style={{color: COLORS.yellow, fontSize: 16}} />;
      standingMessage = `Gelb für ${eventTeamName}`;
      count = primaryRole?.seasonCount ? `(${primaryRole.seasonCount}. Gelbe Karte)` : '';
    } else if (subtype === 'card_yellow_red') {
      icon = <TwoCards style={{fontSize: 16}} />;
      standingMessage = `Gelb-Rot für ${eventTeamName}`;
      count = primaryRole?.seasonCount ? `(${primaryRole.seasonCount}. Gelb-Rote Karte)` : '';
    }
    displayGoalType = `${text} ${count}`;
  }

  // Variant #3: type=penaltyfail
  if (type === 'penaltyfail') {
    icon = <PenaltyMissed style={{fontSize: 16}} />;
    standingMessage = `Elfmeter verschossen von ${eventTeamName}`;
    displayGoalType = goalType[subtype];
    if (secondaryRole) {
      const count = secondaryRole.seasonCount ? `(${secondaryRole.seasonCount}. gehaltener Elfmeter)` : '';
      assist = `Torwart: ${secondaryRole.firstName} ${secondaryRole.lastName} ${count}`;
    }
  }

  const playerContent = (
    <FlexContainer>
      <ImageContainer>{playerImage}</ImageContainer>
      <GoalInformation>
        <Typography variant='body1'>{standingMessage}</Typography>
        <ProfileTypography isDeactivated={isDeactivated} variant='body2'>
          {playerName}
        </ProfileTypography>
        <ProfileTypography isDeactivated={isDeactivated} variant='caption1'>
          {displayGoalType}
        </ProfileTypography>
      </GoalInformation>
    </FlexContainer>
  );

  const playerContainer =
    primaryRole && !isDeactivated ? (
      <LinkOnComponent to={`/player/${primaryRole.slug}`}>{playerContent}</LinkOnComponent>
    ) : (
      playerContent
    );

  // additional text only for goal/penalty events, card events integrate text in playerContainer
  const additionalText =
    type !== 'card' && text ? (
      <Text withPadding variant='body2'>
        {text}
      </Text>
    ) : null;

  return (
    <>
      <ActivityHeader
        title='Liveticker'
        subtitle={subtitle}
        icon={<Alarm style={{color: COLORS.white, fontSize: 16}} />}
        iconColor={COLORS.lightBlue}
        match={activity.activity} // MatchEvent is the only type to pass match object instead of timestamp
      />
      <Content>
        <Container>
          <EventInformation>
            <Minute variant='subheading2' component='span'>
              {time}
            </Minute>
            {icon}
          </EventInformation>
          <DataContainer>
            {playerContainer}
            {assist && (
              <Assist>
                <Typography variant='caption1'>{assist}</Typography>
              </Assist>
            )}
            {additionalText}
          </DataContainer>
        </Container>
      </Content>
    </>
  );
};

MatchEventActivity.propTypes = {
  activity: object.isRequired,
};

export default MatchEventActivity;
