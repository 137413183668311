import {Cancel, CheckCircle, RemoveCircle} from '@mui/icons-material';
import {COLORS} from '@fupa/fupa-uikit';
import React from 'react';

export enum Result {
  Win = 'win',
  Loss = 'loss',
  Draw = 'draw',
}

const mapResultToIcon = {
  [Result.Draw]: (id: number) => <RemoveCircle key={id} sx={{color: COLORS.grey}} fontSize={'small'} />,
  [Result.Win]: (id: number) => <CheckCircle key={id} sx={{color: COLORS.lightGreen}} fontSize={'small'} />,
  [Result.Loss]: (id: number) => <Cancel key={id} sx={{color: COLORS.lightRed}} fontSize={'small'} />,
};

const getFormStandingsLastResults = (lastResults: Result[]) =>
  lastResults?.map((lastResult, id) => {
    return mapResultToIcon[lastResult](id);
  });

export default getFormStandingsLastResults;
