import React from 'react';
import styled from 'styled-components';
import {COLORS, FONTS, Image, SIZES, TextBadge, Typography} from '@fupa/fupa-uikit';
import {Emblem} from 'app/components/icons';
import {convertToRoman} from 'app/helpers/convertToRoman';
import MatchResult from 'app/components/match/matchRow/MatchResult';
import {MatchRowKickoff} from 'app/components/match/matchRow/MatchRowKickoff';
import {LinkOnComponent} from 'app/components/links/link';
import {getMatchStatusAndProgress} from 'app/components/match/matchRow/matchRowHelpers';

const TeamName = styled(Typography)`
  &&& {
    font-size: ${FONTS['10']};
    white-space: nowrap;
    padding-top: 2px;
  }
`;

const TeamResult = styled.div`
  display: flex;
  gap: ${SIZES[16]};
  padding-top: 2px;
`;

const TeamNames = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 3.75rem;
  max-height: 3.75rem;
  width: 10rem;
  border: 1px solid ${COLORS.lighterGrey};
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 349px) {
    width: 9rem;
  }
`;

const EmblemContainer = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
`;

const SCTextBadge = styled(TextBadge)`
  &&& {
    right: 0;
    width: 10px;
    height: 10px;
    font-size: 0.5rem;
  }
`;

const Result = styled.div`
  position: relative;
  width: 46px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FinishedInfo = styled(Typography)`
  &&& {
    position: absolute;
    bottom: -0.5rem;
  }
`;

const TeamImage = ({match, isHomeTeam, size}) => {
  const team = isHomeTeam ? match?.homeTeam : match?.awayTeam;
  const teamNameAlt = team?.name?.full ?? team?.name?.middle ?? team?.placeholder ?? '';
  const fallbackEmblem = <Emblem style={{color: COLORS.lightGrey, fontSize: size}} />;
  const level = team.level > 1 ? convertToRoman(team.level) : null;

  const emblem = team?.image ? (
    <Image alt={teamNameAlt} entity='emblem' src={team.image} width={size} height={size} fit={true} />
  ) : (
    fallbackEmblem
  );

  const levelBadge = level ? <SCTextBadge value={level.toString()} position={1} /> : null;

  return (
    <EmblemContainer>
      {levelBadge}
      {emblem}
    </EmblemContainer>
  );
};

const MatchResultWithInfo = ({match, color}) => {
  const {matchStatus, liveProgress} = getMatchStatusAndProgress(match);
  const additionalInfoConfig = {
    penalty: 'n.E.',
    overtime: 'n.V.',
    verdict: 'Urteil',
    aborted: 'Abbr.',
  };
  const flags = ['penalty', 'overtime', 'verdict', 'aborted'];

  let result;
  const hasVerdict = match.flags?.includes('verdict');
  const isCancelled = match.flags?.includes('cancelled');
  if (isCancelled) {
    result = <Typography variant={'caption1'}>abgesagt</Typography>;
  } else if (!hasVerdict && (matchStatus === 'PRE_STATUS' || matchStatus === 'LIVE_NO_TICKER_STATUS')) {
    result = <MatchRowKickoff match={match} />;
  } else {
    result = (
      <MatchResult
        status={matchStatus === 'LIVE_STATUS' ? 'live' : null}
        progress={liveProgress}
        homeGoal={match.homeGoal}
        awayGoal={match.awayGoal}
        color={color}
      />
    );
  }

  const infoFlag = match.flags?.find(flag => {
    if (flags.includes(flag)) {
      return flag;
    }
  });
  const additionalFinishedInfo = infoFlag ? (
    <FinishedInfo variant={'caption1'}>{additionalInfoConfig[infoFlag]}</FinishedInfo>
  ) : null;

  return (
    <Result>
      {result}
      {additionalFinishedInfo}
    </Result>
  );
};

const TeamMiddleName = ({team}) => {
  let name = team?.name?.middle ?? team?.placeholder ?? '';
  if (name.length > 12) {
    // Need to check length of name because alternative names do not have a character limit
    name = name.substring(0, 10);
    name += '.';
  }
  return <TeamName variant='caption1'>{name}</TeamName>;
};

const MatchStreamTeaser = ({match}) => {
  return (
    <Box as={LinkOnComponent} to={`/match/${match.slug}`}>
      <TeamResult>
        <TeamImage size={32} match={match} isHomeTeam={true} />
        <MatchResultWithInfo match={match} />
        <TeamImage size={32} match={match} isHomeTeam={false} />
      </TeamResult>
      <TeamNames>
        <TeamMiddleName team={match.homeTeam} />
        <TeamMiddleName team={match.awayTeam} />
      </TeamNames>
    </Box>
  );
};

export {MatchStreamTeaser, MatchResultWithInfo};
