import React from 'react';
import {Image} from '@fupa/fupa-uikit';
import styled, {css} from 'styled-components';
import {desktopContentWidth, elementsWidth2Columns, mobileContentWidth} from 'app/styles/media';
import {ImageEntity} from '@fupa/fupa-uikit';
import {ImageInterface as ImageType} from 'app/types/ImageInterface';

const ImageContainer = styled.div<{heroLayout: boolean}>`
  height: 56.25vw;
  width: 100%;
  overflow: hidden;
  padding: 0;
  max-width: ${props => props.theme.mobileContentWidth}px;
  max-height: ${props => (props.theme.mobileContentWidth * 9) / 16}px;

  ${props =>
    props.theme.desktopLayout`
      max-width: ${props.theme.elementsWidth2Columns}px;
      max-height: ${(props.theme.elementsWidth2Columns * 9) / 16}px;

      ${
        props.heroLayout &&
        css`
          flex-flow: row nowrap;
          text-align: start;
          align-items: flex-end;
          justify-content: flex-start;

          width: 100%;
          height: 56.25vw;
          max-width: ${props.theme.desktopContentWidth}px;
          max-height: ${(props.theme.desktopContentWidth * 9) / 16}px;
        `
      }
    `}
`;

interface StreamTeaserImageProps {
  entity: ImageEntity;
  image: ImageType;
  isHero: boolean;
}

const StreamTeaserImage = ({entity, image, isHero}: StreamTeaserImageProps) => {
  // Set media queries as browser hint for sizes to fetch best image dimension due to different actualWidthFactor for mobile and desktop template
  // sizesHint: '(max-width: 640px) 100vw, (max-width: 970px) 640px, 475px'
  const sizesHint = `(max-width: ${mobileContentWidth}px) 100vw, (max-width: ${desktopContentWidth}px) ${mobileContentWidth}px, ${
    isHero ? desktopContentWidth : elementsWidth2Columns
  }px`;

  // Provide actualWidthFactor only to set width and height of <img> to 100%, image dimension is detected because of sizesHint
  const actualWidthFactor = 1;
  return (
    <ImageContainer heroLayout={isHero}>
      <Image
        entity={entity}
        actualWidthFactor={actualWidthFactor}
        sizesHint={sizesHint}
        ratio='16:9'
        src={image}
        eager={isHero}
      />
    </ImageContainer>
  );
};

export {StreamTeaserImage};
